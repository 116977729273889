@charset "utf-8";

*,
::before,
::after {
  box-sizing: border-box;
}
#calendar {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    margin: 0;
  }
  img {
    vertical-align: middle;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  hr {
    height: 1px;
    margin: 6px 0;
    border: none;
    background: $hr_color;
    opacity: 1;
  }

  a {
    text-decoration: none;
    color: $font_color;
  }
  button {
    cursor: pointer;
    border: none;
    background-color: inherit;
    vertical-align: middle;
    //padding: 0;
    &:hover {
      opacity: 0.75;
    }
    &:disabled {
      pointer-events: none;
      cursor: default;
    }
  }
/*
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background: $scrollbar_bg_color;
    border: none;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: $site_color;
    border-radius: 4px;
    box-shadow: none;
  }
*/  

  *:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .alert {
    position: relative;
    padding: 0;
    //margin-bottom: 1rem;
    border: none;
    border-radius: 0;
    color: $error_text_color;
    //font-weight: bold;
  }
  .emphasis {
    font-weight: bold;
  }

  .inline_block {
    display: inline-block;
  }
  .align_center {
    text-align: center;
  }
  .align_left {
    text-align: left;
  }
  .align_right {
    text-align: right;
  }

  span.required {
    display: inline-block;
    text-align: center;
    line-height: 20px;
    width: 32px;
    height: 20px;
    font-size: 12px;
    color: #fff;
    background-color: $required_color;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .position_relative {
    position: relative;
  }

  .box_shadow {
    @include box_shadow();
  }

  .pager {
    text-align: center;
    button {
      font-size: 14px;
      width: 30px;
      color: $pager_text_color;
      &.now {
        cursor: default;
        pointer-events: none;
        color: $font_color;
      }
    }
  }
  .d-none {
    /*Same class as bootstrap*/
    display: none !important;
  }
  .skeleton {
    width: 100%;
    height: 100%;
    min-height: 1rem;
    background: #d9d9d9;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, transparent, rgba(#fff, 0.5), transparent);
      position: absolute;
      top: 0;
      left: 0;
      animation: skeleton-animation 1.2s linear infinite;
    }
  }

  @keyframes skeleton-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
