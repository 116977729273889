@charset "UTF-8";
#calendar {
    input,
    textarea {
        @include fontFamily();
        @include border-default($color: $border_edit_wrap_color);
        @include radius(0);
        &::placeholder {
            color: $placeholder_color;
        }
    }

    .alert {
        position: relative;
        input,
        textarea,
        select {
            @include border-default-important($color: $error_text_color);
        }
        label.for_select:after {
            border-color: $error_text_color transparent transparent transparent !important;
        }
        .error_pop {
            position: absolute;
            border-radius: 5px;
            border: 2px solid $error_text_color !important;
            box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
            position: absolute;
            //top: -21px;
            //-webkit-transform: translateY(-100%);
            //transform: translateY(-100%);
            background-color: #fff;
            //display: -webkit-flex;
            //display: flex;
            //-webkit-flex-direction: column;
            //flex-direction: column;
            white-space: nowrap;
            padding: 5px;
            line-height: 1.5em;
            z-index: 10;
            font-weight: bold;
            color: $error_text_color;
            &::before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 8px 0 8px;
                border-color: $error_text_color transparent transparent transparent;
                position: absolute;
                bottom: -12px;
                left: 10px;
            }
            &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 6px 0 6px;
                border-color: #fff transparent transparent transparent;
                position: absolute;
                bottom: -8px;
                left: 12px;
            }
        }
        /*
        &.pos_bottom {
        top: inherit;
        bottom: calc(-81px);
        &::before {
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent $error_text_color transparent;
            top: -11px;
            bottom: inherit;
            left: 10px;
        }
    }*/
    }

    /*input*/
    input[type="text"],
    input[type="date"],
    input[type="url"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="password"] {
        line-height: 40px;
        padding: 0 10px;
        color: $font_color;
        &.x_small {
            width: 70px;
        }
        &.small {
            width: 40%;
        }
        &.medium {
            width: 70% /*350px*/;
        }
        &.large {
            width: 100%;
        }
        &.auto {
            width: auto;
        }
    }

    /* Chrome/Safari */
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input.add_reset {
        padding-right: 40px;
    }

    .add_reset_wrap {
        //for 組織名
        input.add_reset {
            width: calc(100% - 10px);
        }
        button.reset {
            top: 16px;
            right: 20px;
            left: auto;
        }
    }

    /*textarea*/
    textarea {
        width: 100%;
        @include radius(5px);
    }

    /*select*/
    label.for_select {
        position: relative;
        display: inline-block;
        &:after {
            position: absolute;
            top: 18px;
            right: 10px;
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: $site_color transparent transparent transparent;
            pointer-events: none;
        }
        &.multi {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        select {
            @include border-default($color: $border_edit_wrap_color);
            @include radius(0px);
            position: relative;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: $form_text_color;
            background-color: transparent;
            height: 40px;
            min-width: 70px;
            vertical-align: middle;
            padding: 0 30px 0 10px;
            /*z-index: 1;*/
        }
        ::-ms-expand {
            display: none;
        }
    }
    .select_wrap {
        //ダイアログ用
        label.for_select {
            position: relative;
            display: inline-block;
            height: 40px;
            &:after {
                top: 17px;
            }
        }
    }

    /*
    .search_word {
        margin-bottom: $margin_bottom_default;
        width: 100%;
        font-size: 0;
        input {
            width: calc(100% - 32px);
            line-height: 30px;
            height: 32px;
            padding-left: 10px;
            @include border-default($color:$form_color);
            border-right: none;
            vertical-align: top;
            font-size: 14px;
            padding: 0 10px;
        }
        button {
            width: 32px;
            height: 32px;
            padding: 0;
            color: white;
            background-color: $main_header_color;
            border: none;
        }
    }

    .file_upload {
        width: 100%;
        input {
            width: calc(100% - 70px) !important;
            line-height: 30px;
            height: 32px;
            padding-left: 10px;
            @include border-default($color:$form_color);
            border-right: none;
            vertical-align: top;
            font-size: 14px;
            padding: 0 10px;
            border-right: none !important;
        }
        .fileBtn {
            width: 70px;
            height: 32px;
            padding: 0;
            color: white;
            background-color: $btn_secondary_color;
            border: none;
            line-height: 32px;
            margin: 0;
            text-align: center;
            cursor: pointer;
            &:hover {
                opacity: 0.75;
            }
            input[type="file"] {
                display: none;
            }
        }
    }
*/
    /*
form {
        label.for_select {
            margin-bottom: 0;
            &:after {
                top: 11px;
            }
            + br + label.for_select {
                margin-top: 5px;
            }
        }
        select,
        input[type="text"],
        input[type="date"],
        input[type="url"],
        input[type="email"],
        input[type="tel"],
        textarea {
            color: $form_text_color;
            background-color: $form_background_color;
            @include border-default($color:$form_border_color);
            min-height: 32px;
        }
        input[type="text"],
        input[type="date"],
        input[type="url"],
        input[type="email"],
        input[type="tel"],
        textarea {
            width: 100%;
            padding-left: 6px;
            &.w_sm {
                width: 60%;
            }
            &.w_auto {
                width: auto;
            }
        }
        select {
            min-width: 150px;
        }
        select[name="location_pref"] {
            width: 150px;
        }
        input[name="article_id"]{
                width: calc(100% - 150px - 50px);
        }            

        input[name="location"]{
            width: calc(100% - 150px - 10px);
            margin-left: 10px;
        }
        #expired {
            input[type="date"]{
                margin-bottom: 5px;
            }
        }
        input[type="date"]{
            position: relative;
            width: 170px;
        }
        input[type="date"] + i {
            position: absolute;
            // font-family: "Font Awesome 5 Free";
            // content: "\f073";
            // top: 0px;
            // right: 0px;
            // background-color: #FF0000;
            font-size: 1.2rem;
            font-weight: 400;
            width: 32px;
            height: 32px;
            margin-left: -32px;
            text-align: center;
            line-height: 32px;
            vertical-align: bottom;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
            position: absolute;
            z-index: 2;
            opacity: 0;
            top: 0px;
            right: 0px;
            width: 1.5rem;
            height:  1.8rem;
            cursor: pointer;
        }
        input[type="date"]::-webkit-inner-spin-button{
            -webkit-appearance: none;
        }
        input[type="date"]::-webkit-clear-button{
            -webkit-appearance: none;
        }
        textarea {
            width: 100%;
            min-height: 100px;
            vertical-align: bottom;
        }
}
*/

    /*
  radio style
*/
    $radio_style_checked_color: $radio_color;
    $radio_style_border_color: $radio_color;
    $radio_style_size: 26px;
    $radio_style_checked_size: 18px;
    $radio_style_ripple_size: 10px;

    @keyframes ripple {
        0% {
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
        }
        50% {
            box-shadow: 0px 0px 0px $radio_style_ripple_size rgba(0, 0, 0, 0.1);
        }
        100% {
            box-shadow: 0px 0px 0px $radio_style_ripple_size rgba(0, 0, 0, 0);
        }
    }

    .radio {
        margin: 0px 5px 0px 0;
        input[type="radio"] {
          display: none;
          // &:checked + label:before {
          //     //border-color: $radio_style_checked_color;
          //     animation: ripple 0.2s linear forwards;
          // }
          // &:checked + label {
          //     //font-weight: bold;
          // }
          // &:checked + label:after {
          //     transform: scale(1);
          // }
        }
        label {
          display: inline-block;
          min-height: $radio_style_size;
          position: relative;
          padding: 0 10px 0 ($radio_style_size + 5px);
          margin-top: 5px;
          margin-bottom: 5px;
          cursor: pointer;
          vertical-align: bottom;
          white-space: nowrap;
          line-height: $radio_style_size;
          &:before,
          &:after {
            position: absolute;
            content: "";
            border-radius: 50%;
            transition: all 0.3s ease;
            transition-property: transform, border-color;
          }
          &:before {
            left: 0;
            top: 0;
            width: $radio_style_size;
            height: $radio_style_size;
            border: 1px solid $radio_style_border_color;
          }
          &:after {
            top: $radio_style_size / 2 - $radio_style_checked_size / 2;
            left: $radio_style_size / 2 - $radio_style_checked_size / 2;
            width: $radio_style_checked_size;
            height: $radio_style_checked_size;
            transform: scale(0);
            background: $site_color;
          }
          &.checked::before {
            animation: ripple 0.2s linear forwards;
            border: 1px solid $site_color;
          }
          &.checked::after {
            transform: scale(1);
          }
        }
      }

    /*
  check style
*/
    /* Base for label styling */
    .checkbox {
        display: inline-block;
        height: 26px;
        line-height: 26px;
        margin: 5px 0;
    }
    .checkbox input[type="checkbox"]:not(:checked),
    .checkbox input[type="checkbox"]:checked {
        /*position: absolute;
        left: -9999px;*/
        display: none;
    }
    .checkbox label {
    /*.checkbox input[type="checkbox"] + label,
    .checkbox input[type="checkbox"]:checked label {*/
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 26px;
        line-height: 26px;
        margin: 0 20px 0 0;
        padding-left: 2.2em;
        font-size: 14px !important;
        font-weight: normal !important;
        white-space: nowrap;
    }
    /*.checkbox input[type="checkbox"]:checked + label {
        font-weight: bold;
    }*/

    /* checkbox aspect */
    /*.checkbox input[type="checkbox"] + label:before,
    .checkbox input[type="checkbox"]:checked + label:before {*/
    .checkbox label:before {
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
        width: 24px;
        height: 24px;
        border: 1px solid $form_checkbox_border_color;
        background: #fff;
    }
    /*.checkbox input[type="checkbox"]:checked + label:before {*/
    .checkbox label.checked::before {
        background: $site_color;
        border: 1px solid $site_color;
    }

    /* checked mark aspect */
    /*.checkbox input[type="checkbox"] + label:after,
    .checkbox input[type="checkbox"]:checked + label:after {*/
    .checkbox label.checked::after {
        //font-family: "Font Awesome 5 Free";
        content: /*"\f00c"*/ "";
        font-weight: 900;
        position: absolute;
        top: 2px;
        left: 7px;
        font-size: 1em;
        line-height: 1;
        transition: all 0.2s;
        color: white;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        width: 10px;
        height: 16px;
    }
    /* checked mark aspect changes */
    /*.checkbox input[type="checkbox"] + label:after {*/
    .checkbox label::after {    
        opacity: 0;
        transform: scale(0);
    }
    /*.checkbox input[type="checkbox"]:checked + label:after {*/
    .checkbox label.checked::after {    
        opacity: 1;
        transform: scale(1) rotate(45deg);
    }
    /* disabled checkbox */
    /*
.checkbox input[type="checkbox"]:disabled:not(:checked) + label:before,
.checkbox input[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}
.checkbox input[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}
.checkbox input[type="checkbox"]:disabled + label {
    color: #aaa;
}*/
    .checkbox input[type="checkbox"].empty_label + label {
        width: 0;
        height: 22px;
        margin: 0;
        line-height: 1rem;
        padding: 0 0 0 22px;
        vertical-align: middle;
    }
}
