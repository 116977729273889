@charset "utf-8";

$site_color: #0097DD;
$site_sub_color: #D5E9F0;
$accent_color: #E35D67;

$selectecd_color: #FFF9D9; //選択
$invalid_color: #CCCCCC; //無効、削除

/*font color*/
$font_color: /*#707070*/#545454;
$error_text_color: #E35D67;
$placeholder_color: rgba(112,112,112,0.6);

/*border*/
$border_default_color :#E2E2E3;
$border_edit_wrap_color :#545454;

/*hr*/
$hr_color:rgba(0,0,0,0.03);

/*table*/
$table_color:#D1DDED;
$table_thead_background_color:rgba(0,0,0,0.078);
$table_thead_color:rgba(0,0,0,0.87);
$table_thead_line_color :#3554844D;
$sub_table_thead_background_color:#D6DDE6;

/*$table_thead_color:#FFFFFF;*/
$table_td_even_color:/*rgba(0, 0, 0, 0.03)*/#F7F7F7;
$table_td_odd_color:#FFF;
$table_th_selected_color:rgba(23,163,180,0.4);
$table_sunday: #FFEEEE;
$table_sunday_text: #FF0000;
$table_saturday: #EBFBFF;
$table_saturday_text: #0099EB;
$table_other_month: #DDDDDD;
$table_today: #e8fde7;
$table_holiday: #DDDDDD;

/*table for edit*/
$loading_background_color: rgba(255,255,255,.75);
$required_color:#F90000;

/*button*/
$btn_primary_color: $site_color;
$btn_secondary_color: #0C53B1;
$btn_tertiary_color: #FFFFFF;
$btn_disabled_color: #BBB;
$btn_disabled_border_color: #9E9E9E;

/*radio*/
$radio_color: #4D4D4D;

/*scrollbar*/
$scrollbar_bg_color: #E0E0E0;
$scrollbar_color: $site_color;

/*type*//*for winds*/
$tour_color_default:#E59248;
$tour_color_1:#FF6B6B;
$tour_color_2:#4D96FF;
$tour_color_3:#5FB368;
$tour_color_4:#CCA300;
$tour_color_5:#9E3200;
$tour_color_6:#000DFF;
$tour_color_7:#008C52;
$tour_color_8:#7A6B00;
$tour_color_9:#E500BA;
$tour_color_10:#4100A8;

/*
$main_header_color: #606060;
$header_footer_background: #F2F2F2;
$header_text_color: #707070;
$header_border_color: #1558a7;
// $footer_text_color: #606060;
$footer_text_color: #000000;
$auth_footer_background_color: rgba(255,255,255,0.5);
$auth_footer_text_color: #000000;
*/
/*
$margin_bottom_default: 10px;

$list_background_color :#F2F2F2;
$list_background_disabled_color :#E4E4E4;
$list_id_background_color :#7F7F7F;

$drag_area_background_color : #EDEDED;
*/

/*form*/
$form_color: #BFBFBF;
$form_border_color: #DDDDDD;
$form_background_color: #FAFAFA;
$form_file_background_color: #ecf0f6;
$form_text_color: #606060;
$form_checkbox_border_color:#707070;
$form_checkbox_background_color:#FFF;
$form_checkbox_check_color:#707070;
$error_text_color: #E35D67;

$disabled_background_color: #EEEEEE;
$disabled_color: #CFCFCF;

$sidemenu_current_background_color: rgba(255, 255, 255, 0.75);

$icon_off_color:#DBDCDE;
$active_border_color:#9AB4D6;
$thumbnail_border_color: #707070;
$link_color: #707070;
$common_title_border_color: #BFBFBF;

/*modal*/
$modal_overlay_color: rgba(0,0,0,.33);
$modal_close_color: #606060;

/*pager*/
$pager_text_color: rgba(33,133,208,0.87);